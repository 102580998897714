import React from 'react';

import './index.scss';
import { OngoingPlaytest, playsConfig } from '../containers/OngoingPlaytest';
import WindowProvider from '../components/WinowProvider';
import SEO from '../components/Seo';
import Layout from '../components/Layout';

function PlayTests() {
  return (
    <WindowProvider>
      <SEO>
        <Layout>
          <OngoingPlaytest />
        </Layout>
      </SEO>
    </WindowProvider>
  );
}

export default PlayTests;
